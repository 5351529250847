import React from "react";

export default function Terms() {
  return (
    <div>
      <br></br>
      {/* <h1>Privacy Policy</h1> */}
      {/* <br></br> */}
      <h3>
        <strong>End User Agreement</strong>
      </h3>
      <h4>
        <strong>
          Venndii Inc. (DBA and Trade name as &ldquo;Wander&rdquo;)
        </strong>
      </h4>
      <p>
        You are entering into this Contract with Venndii Inc. (also referred to
        as &ldquo;Wander&rdquo;, &ldquo;we&rdquo; and &ldquo;us&rdquo;).
      </p>
      <p>This Contract applies to Members and Visitors.</p>
      <p>
        As a Visitor or Member of our Services, the collection, use and sharing
        of your personal data is subject to our Privacy Policy and other related
        documents.
      </p>
      <p>
        <strong>1.1 Contract</strong>
      </p>
      <p>
        When you use our Services you agree to all of these terms. Your use of
        our Services is also subject to our Privacy Policy, which covers how we
        collect, use, share, and store your personal information.
      </p>
      <p>
        You agree that by clicking &ldquo;Sign Up&rdquo; or similar,
        registering, accessing or using our services (described below),{" "}
        <strong>
          you are agreeing to enter into a legally binding contract
        </strong>{" "}
        with Wander (even if you are using our Services on behalf of a company).
        If you do not agree to this contract (&ldquo;Contract&rdquo; or
        &ldquo;User Agreement&rdquo;), do <strong>not</strong> click &ldquo;Join
        Now&rdquo; (or similar) and do not access or otherwise use any of our
        Services. If you wish to terminate this contract, at any time you can do
        so by closing your account and no longer accessing or using our
        Services.
      </p>
      <h4>
        <strong>Services</strong>
      </h4>
      <p>
        This Contract applies to Wander and other Wander-related sites, apps,
        communications and other services that state that they are offered under
        this Contract (&ldquo;Services&rdquo;), including the offsite collection
        of data for those Services.
      </p>
      <p>&nbsp;Registered users of our Services are &ldquo;Members&rsquo;.</p>
      <p>When you register and join the Wander you become a Member.</p>
      <h3>
        <strong>1.3 Change</strong>
      </h3>
      <p>We may make changes to the Contract.</p>
      <p>
        We may modify this Contract, our Privacy Policy and other documents from
        time to time. If we make material changes to it, we will provide you
        notice through our Services, or by other means, to provide you the
        opportunity to review the changes before they become effective. We agree
        that changes cannot be retroactive. If you object to any changes, you
        may close your account. Your continued use of our Services after we
        publish or send a notice about our changes to these terms means that you
        are consenting to the updated terms as of their effective date
      </p>
      <h3>
        <strong>2.1 Service Eligibility</strong>
      </h3>
      <p>Here are some promises that you make to us in this Contract:</p>
      <p>
        You&rsquo;re eligible to enter into this Contract and you are at least
        our &ldquo;Minimum Age.&rdquo;
      </p>
      <p>The Services are not for use by anyone under the age of 18.</p>
      <p>
        To use the Services, you agree that: (1) you must be the &ldquo;Minimum
        Age&rdquo; (described below) or older; (2) you will only have one Wander
        account, which must be in your real name; and (3) you are not already
        restricted by Wander from using the Services. Creating an account with
        false information is a violation of our terms, including accounts
        registered on behalf of others or persons under the age of 18.
      </p>
      <p>
        &ldquo;Minimum Age&rdquo; means 18 years old. However, if law requires
        that you must be older in order for Wander to lawfully provide the
        Services to you without parental consent (including using of your
        personal data) then the Minimum Age is such older age.
      </p>
      <h3>
        <strong>2.2 Your Account</strong>
      </h3>
      <p>You will keep your password a secret.</p>
      <p>
        You will not share an account with anyone else and will follow our rules
        and the law.
      </p>
      <p>Members are account holders.</p>
      <p>
        You are responsible for anything that happens through your account
        unless you close it or report misuse.
      </p>
      <h3>
        <strong>2.3 Payment</strong>
      </h3>
      <p>
        You will honor your payment obligations and you are okay with us storing
        your payment information. You understand that there may be fees and
        taxes that are added to our prices. Refunds are subject to our policy.
      </p>
      <p>
        If you buy any of our paid Services (&ldquo;Premium Services&rdquo;),
        you agree to pay us the applicable fees and taxes and to additional
        terms specific to the paid Services. Failure to pay these fees will
        result in the termination of your paid Services. Also, you agree that:
      </p>
      <ul>
        <li>
          Your purchase may be subject to foreign exchange fees or differences
          in prices based on location (e.g. exchange rates).
        </li>
        <li>
          We may store and continue billing your payment method (e.g. credit
          card) even after it has expired, to avoid interruptions in your
          Services and to use to pay other Services you may buy.
        </li>
        <li>
          If you purchase a subscription, your payment method automatically will
          be charged at the start of each subscription period for the fees and
          taxes applicable to that period. To avoid future charges, cancel
          before the renewal date. Learn how to cancel or suspend your Premium
          Services.
        </li>
        <li>
          All of your purchases of Services are subject to Wander&rsquo;s Refund
          Policy.
        </li>
        <li>
          We may calculate taxes payable by you based on the billing information
          that you provide us at the time of purchase.
        </li>
      </ul>
      <p>
        You can get a copy of your invoice through your Wander account settings
        under &ldquo;Purchase History&rdquo;.
      </p>
      <h3>
        <strong>2.4 Notices and Messages</strong>
      </h3>
      <p>
        You are okay with us providing notices and messages to you through our
        websites, apps, and contact information. If your contact information is
        out of date, you may miss out on important notices.
      </p>
      <p>
        You agree that we will provide notices and messages to you in the
        following ways: (1) within the Service, or (2) sent to the contact
        information you provided us (e.g., email, mobile number, physical
        address). You agree to keep your contact information up to date.
      </p>
      <p>
        Please review your settings to control and limit messages you receive
        from us.
      </p>
      <h3>
        <strong>2.5 Sharing</strong>
      </h3>
      <p>
        When you share information on our Services, others can see, copy and use
        that information.
      </p>
      <p>
        Our Services allow messaging and sharing of information in many ways,
        such as your profile, articles, group posts, links to news articles,
        messages and emails. Information and content that you share or post may
        be seen by other Members, Visitors or others (including off of the
        Services). Where we have made settings available, we will honor the
        choices you make about who can see content or information (e.g., message
        content to your addressees, sharing content only to Wander connections,
        restricting your profile visibility from search engines, or opting not
        to notify others of your Wander profile update.
      </p>
      <p>
        We are not obligated to publish any information or content on our
        Service and can remove it with or without notice.
      </p>
      <h3>
        <strong>3.1. Your License to Wander</strong>
      </h3>
      <p>
        You own all of the content, feedback and personal information you
        provide to us, but you also grant us a non-exclusive license to it.
      </p>
      <p>
        We will honor the choices you make about who gets to see your
        information and content, including how it can be used for ads.
      </p>
      <p>
        As between you and Wander, you own the content and information that you
        submit or post to the Services, and you are only granting Wander and our
        affiliates the following non-exclusive license:
      </p>
      <p>
        A worldwide, transferable and sublicensable right to use, copy, modify,
        distribute, publish and process, information and content that you
        provide through our Services and the services of others, without any
        further consent, notice and/or compensation to you or others. These
        rights are limited in the following ways:
      </p>
      <ol>
        <li>
          You can end this license for specific content by deleting such content
          from the Services, or generally by closing your account, except (a) to
          the extent you shared it with others as part of the Service and they
          copied, re-shared it or stored it and (b) for the reasonable time it
          takes to remove from backup and other systems.
        </li>
        <li>
          We include your content in advertisements for the products and
          services of third parties to others without your separate consent
          (including sponsored content). However, we have the right, without
          payment to you or others, to serve ads near your content and
          information, and your social actions may be visible and included with
          ads, as noted in the Privacy Policy. If you use a Service feature, we
          may mention that with your name or photo to promote that feature
          within our Services, subject to your settings.
        </li>
        <li>
          We will obtain your consent if we want to give others the right to
          publish your content beyond the Services. However, if you choose to
          share your post as "public", we will enable a feature that allows
          other Members to embed that public post onto third-party services, and
          we enable search engines to make that public content findable though
          their services.&nbsp;
        </li>
        <li>
          While we may edit and make format changes to your content (such as
          translating or transcribing it, modifying the size, layout or file
          type or removing metadata), we will not modify the meaning of your
          expression.
        </li>
        <li>
          Because you own your content and information and we only have
          non-exclusive rights to it, you may choose to make it available to
          others.
        </li>
      </ol>
      <p>
        You and Wander agree that if content includes personal data, it is
        subject to our Privacy Policy.
      </p>
      <p>
        You and Wander agree that we may access, store, process and use any
        information and personal data that you provide in accordance with the
        terms of the Privacy Policy and your choices (including settings).
      </p>
      <p>
        By submitting suggestions or other feedback regarding our Services to
        Wander, you agree that Wander can use and share (but does not have to)
        such feedback for any purpose without compensation to you.
      </p>
      <p>
        You promise to only provide information and content that you have the
        right to share, and that your Wander profile will be truthful.
      </p>
      <p>
        You agree to only provide content or information that does not violate
        the law nor anyone&rsquo;s rights (including intellectual property
        rights). You also agree that your profile information will be truthful.
        Wander may be required by law to remove certain information or content
        in certain countries.
      </p>
      <h3>
        <strong>3.2 Service Availability</strong>
      </h3>
      <p>
        We may change or end any Service or modify our prices prospectively.
      </p>
      <p>
        We may change, suspend or discontinue any of our Services. We may also
        modify our prices effective prospectively upon reasonable notice to the
        extent allowed under the law.
      </p>
      <p>
        We don&rsquo;t promise to store or keep showing any information and
        content that you&rsquo;ve posted. Wander is not a storage service. You
        agree that we have no obligation to store, maintain or provide you a
        copy of any content or information that you or others provide, except to
        the extent required by applicable law and as noted in our Privacy
        Policy.
      </p>
      <h3>
        <strong>3.3 Other Content, Sites and Apps</strong>
      </h3>
      <p>
        Your use of others&rsquo; content and information posted on our
        Services, is at your own risk.
      </p>
      <p>
        Others may offer their own products and services through our Services,
        and we aren&rsquo;t responsible for those third-party activities.
      </p>
      <p>
        By using the Services, you may encounter content or information that
        might be inaccurate, incomplete, delayed, misleading, illegal, offensive
        or otherwise harmful. Wander generally does not review content provided
        by our Members or others. You agree that we are not responsible for
        others&rsquo; (including other Members&rsquo;) content or information.
        We cannot always prevent this misuse of our Services, and you agree that
        we are not responsible for any such misuse. You also acknowledge the
        risk that you or your organization may be mistakenly associated with
        content about others when we let connections and followers know you or
        your organization were mentioned in the news.
      </p>
      <p>
        Wander may facilitate connection of Members offering their services
        (tour guides, excursions, etc.) with Members seeking services. Wander
        does not perform nor employs individuals to perform these services. You
        must be at least 18 years of age to offer, perform or procure these
        services. You acknowledge that Wander does not supervise, direct,
        control or monitor Members in the performance of these services and
        agree that (1) Wander is not responsible for the offering, performance
        or procurement of these services, (2) Wander does not endorse any
        particular Member&rsquo;s offered services, and (3) nothing shall create
        an employment, agency, or joint venture relationship between Wander and
        any Member offering services. If you are a Member offering services, you
        represent and warrant that you have all the required licenses and will
        provide services consistent with our Policy
      </p>
      <p>
        Similarly, Wander may help you register for and/or attend events
        organized by Members and connect with other Members who are attendees at
        such events. You agree that (1) Wander is not responsible for the
        conduct of any of the Members or other attendees at such events, (2)
        Wander does not endorse any particular event listed on our Services, (3)
        Wander does not review and/or vet any of these events, and (4) that you
        will adhere to these terms and conditions that apply to such events.
      </p>
      <h3>
        <strong>3.4 Limits</strong>
      </h3>
      <p>
        We have the right to limit how you connect and interact on our Services.
      </p>
      <p>
        Wander reserves the right to limit your use of the Services, including
        the number of your connections and your ability to contact other
        Members. Wander reserves the right to restrict, suspend, or terminate
        your account if you breach this Contract or the law or are misusing the
        Services.
      </p>
      <h3>
        <strong>3.5 Intellectual Property Rights</strong>
      </h3>
      <p>We are providing you notice about our intellectual property rights.</p>
      <p>
        Wander reserves all of its intellectual property rights in the Services.
        Trademarks and logos used in connection with the Services are the
        trademarks of their respective owners. Wander, and &ldquo;in&rdquo;
        logos and other Wander trademarks, service marks, graphics and logos
        used for our Services are trademarks or registered trademarks of Wander.
      </p>
      <h3>
        <strong>3.6 Automated Processing</strong>
      </h3>
      <p>
        We use data and information about you to make relevant suggestions to
        you and others.
      </p>
      <p>
        We use the information and data that you provide and that we have about
        Members to make recommendations for connections, content and features
        that may be useful to you. For example, we use data and information
        about you to recommend ideal personal connections and relationships.
        Keeping your profile accurate and up to date helps us to make these
        recommendations more accurate and relevant.
      </p>
      <h3>
        <strong>4.1 No Warranty</strong>
      </h3>
      <p>
        This is our disclaimer of legal liability for the quality, safety, or
        reliability of our Services.
      </p>
      <p>
        <em>
          WANDER AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY ABOUT THE
          SERVICES, INCLUDING ANY REPRESENTATION THAT THE SERVICES WILL BE
          UNINTERRUPTED OR ERROR-FREE, AND PROVIDE THE SERVICES (INCLUDING
          CONTENT AND INFORMATION) ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS
          AVAILABLE&rdquo; BASIS. TO THE FULLEST EXTENT PERMITTED UNDER
          APPLICABLE LAW, WANDER AND ITS AFFILIATES DISCLAIM ANY IMPLIED OR
          STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY
          OF DATA, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR
          PURPOSE
        </em>
        .
      </p>
      <h3>
        <strong>4.2 Exclusion of Liability</strong>
      </h3>
      <p>These are the limits of legal liability we may have to you.</p>
      <p>
        <em>
          TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS WANDER HAS ENTERED
          INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS CONTRACT),
          WANDER, INCLUDING ITS AFFILIATES, WILL HELD HARMLESS IN CONNECTION
          WITH THIS CONTRACT FOR LOST PROFITS OR LOST BUSINESS OPPORTUNITIES,
          REPUTATION (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA
          (E.G., DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR
          CONTENT) OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR
          PUNITIVE DAMAGES.
        </em>
      </p>
      <p>
        <em>
          WANDER AND ITS AFFILIATES WILL NOT BE LIABLE TO YOU IN CONNECTION WITH
          THIS CONTRACT FOR ANY AMOUNT THAT EXCEEDS (A) THE TOTAL FEES PAID OR
          PAYABLE BY YOU TO WANDER FOR THE SERVICES DURING THE TERM OF THIS
          CONTRACT, IF ANY, OR (B) US $1000.
        </em>
      </p>
      <h3>
        <strong>4.3 Basis of the Bargain; Exclusions</strong>
      </h3>
      <p>
        The limitations of liability in this Section 4 are part of the basis of
        the bargain between you and Wander and shall apply to all claims of
        liability (e.g., warranty, tort, negligence, contract and law) even if
        Wander or its affiliates has been told of the possibility of any such
        damage, and even if these remedies fail their essential purpose.
      </p>
      <p>
        These limitations of liability do not apply to liability for death or
        personal injury or for fraud, gross negligence or intentional
        misconduct, or in cases of negligence where a material obligation has
        been breached, a material obligation being such which forms a
        prerequisite to our delivery of services and on which you may reasonably
        rely, but only to the extent that the damages were directly caused by
        the breach and were foreseeable upon conclusion of this Contract and to
        the extent that they are typical in the context of this Contract.
      </p>
      <p>
        <strong>5.1 Termination</strong>
      </p>
      <p>
        We can each end this Contract, but some rights and obligations survive.
      </p>
      <p>
        Both you and Wander may terminate this Contract at any time with notice
        to the other. On termination, you lose the right to access or use the
        Services. The following shall survive termination:
      </p>
      <ul>
        <li>Our rights to use and disclose your feedback;</li>
        <li>
          Members and/or Visitors&rsquo; rights to further re-share content and
          information you shared through the Services;
        </li>
        <li>Sections 4, 6, 7, and 8.2 of this Contract;</li>
        <li>
          Any amounts owed by either party prior to termination remain owed
          after termination.
        </li>
      </ul>
      <p>
        <strong>6.1 Dispute Resolution</strong>
      </p>
      <p>
        In the unlikely event we end up in a legal dispute, you and Wander agree
        to resolve it in Ontario, Canada courts using Canadian law.
      </p>
      <p>
        This section shall not deprive you of any mandatory consumer protections
        under the law of the country to which we direct Services to you, where
        you have your habitual residence.{" "}
        <strong>If you live in the Designated Countries</strong>: You and Wander
        agree that the laws of of the Designated Country, excluding conflict of
        laws rules, shall exclusively govern any dispute relating to this
        Contract and/or the Services. You and Wander agree that claims and
        disputes can be litigated only in the Designated Country, and we each
        agree to personal jurisdiction of the courts located in the Designated
        Country.
      </p>
      <p>
        <strong>
          For others outside of Designated Countries, including those who live
          outside of the United States
        </strong>
        : You and Wander agree that the laws of the Province of Ontario, Canada,
        excluding its conflict of laws rules, shall exclusively govern any
        dispute relating to this Contract and/or the Services. You and Wander
        both agree that all claims and disputes can be litigated only in the
        federal or provincial courts in Ontario, Canada, and you and Wander each
        agree to personal jurisdiction in those courts.
      </p>
      <p>
        <strong>7.1 General Terms</strong>
      </p>
      <p>Here are some important details about the Contract.</p>
      <p>
        If a court with authority over this Contract finds any part of it
        unenforceable, you and we agree that the court should modify the terms
        to make that part enforceable while still achieving its intent. If the
        court cannot do that, you and we agree to ask the court to remove that
        unenforceable part and still enforce the rest of this Contract.
      </p>
      <p>
        This Contract (including additional terms that may be provided by us
        when you engage with a feature of the Services) is the only agreement
        between us regarding the Services and supersedes all prior agreements
        for the Services.
      </p>
      <p>
        If we don't act to enforce a breach of this Contract, that does not mean
        that Wander has waived its right to enforce this Contract. You may not
        assign or transfer this Contract (or your membership or use of Services)
        to anyone without our consent. However, you agree that Wander may assign
        this Contract to its affiliates or a party that buys it without your
        consent. There are no third-party beneficiaries to this Contract.
      </p>
      <p>
        You agree that the only way to provide us legal notice is at the
        addresses provided in Section 10.
      </p>
      <h3>
        <strong>8.1. Dos</strong>
      </h3>
      <p>Wander is a community of members.</p>
      <p>
        <strong>You agree that you will:</strong>
      </p>
      <ol>
        <li>
          Comply with all applicable laws, including, without limitation,
          privacy laws, intellectual property laws, anti-spam laws, export
          control laws, tax laws, and regulatory requirements;
        </li>
        <li>Provide accurate information to us and keep it updated;</li>
        <li>Use your real name on your profile; and</li>
        <li>Use the Services in a professional manner.</li>
      </ol>
      <h3>
        <strong>8.2. Don&rsquo;ts</strong>
      </h3>
      <p>
        <strong>You agree that you will </strong>
        <strong>
          <em>not</em>
        </strong>
        <strong>:</strong>
      </p>
      <ol>
        <li>
          Create a false identity on Wander, misrepresent your identity, create
          a Member profile for anyone other than yourself (a real person), or
          use or attempt to use another&rsquo;s account;
        </li>
        <li>
          Develop, support or use software, devices, scripts, robots or any
          other means or processes (including crawlers, browser plugins and
          add-ons or any other technology) to scrape the Services or otherwise
          copy profiles and other data from the Services;
        </li>
        <li>
          Override any security feature or bypass or circumvent any access
          controls or use limits of the Service (such as caps on keyword
          searches or profile views);
        </li>
        <li>
          Copy, use, disclose or distribute any information obtained from the
          Services, whether directly or through third parties (such as search
          engines), without the consent of Wander;
        </li>
        <li>
          Disclose information that you do not have the consent to disclose
          (such as confidential information of others (including your
          employer));
        </li>
        <li>
          Violate the intellectual property rights of others, including
          copyrights, patents, trademarks, trade secrets or other proprietary
          rights. For example, do not copy or distribute (except through the
          available sharing functionality) the posts or other content of others
          without their permission, which they may give by posting under a
          Creative Commons license;
        </li>
        <li>
          Violate the intellectual property or other rights of Wander,
          including, without limitation, (i) copying or distributing our
          learning videos or other materials or (ii) copying or distributing our
          technology, unless it is released under open source licenses; (iii)
          using the word &ldquo;Wander&rdquo; or our logos in any business name,
          email, or URL except as provided.
        </li>
      </ol>
      <ol>
        <li>
          Post anything that contains software viruses, worms, or any other
          harmful code;
        </li>
        <li>
          Reverse engineer, decompile, disassemble, decipher or otherwise
          attempt to derive the source code for the Services or any related
          technology that is not open source;
        </li>
        <li>
          Imply or state that you are affiliated with or endorsed by Wander
          without our express consent (e.g., representing yourself as an
          accredited Wander trainer);
        </li>
        <li>
          Rent, lease, loan, trade, sell/re-sell or otherwise monetize the
          Services or related data or access to the same, without Wander&rsquo;s
          consent;
        </li>
        <li>
          Deep-link to our Services for any purpose other than to promote your
          profile or a Group on our Services, without Wander&rsquo;s consent;
        </li>
        <li>
          Use bots or other automated methods to access the Services, add or
          download contacts, send or redirect messages;
        </li>
        <li>
          Monitor the Services&rsquo; availability, performance or functionality
          for any competitive purpose;
        </li>
        <li>
          Engage in &ldquo;framing,&rdquo; &ldquo;mirroring,&rdquo; or otherwise
          simulating the appearance or function of the Services;
        </li>
        <li>
          Overlay or otherwise modify the Services or their appearance (such as
          by inserting elements into the Services or removing, covering, or
          obscuring an advertisement included on the Services);
        </li>
        <li>
          Interfere with the operation of, or place an unreasonable load on, the
          Services (e.g., spam, denial of service attack, viruses, gaming
          algorithms); and/or
        </li>
        <li>
          Violate the Wander Policy or any additional terms concerning a
          specific Service that are provided when you sign up for or start using
          such Service.
        </li>
      </ol>
      <h3>
        <strong>9.1 Complaints Regarding Content</strong>
      </h3>
      <p>
        Contact information for complaint about content provided by our Members.
      </p>
      <p>
        We respect the intellectual property rights of others. We require that
        information posted by Members be accurate and not in violation of the
        intellectual property rights or other rights of third parties.
      </p>
      <h3>
        <strong>10.1 How to Reach Us</strong>
      </h3>
      <p>Our Contact information:</p>
      <p>Email: travel@wander.social</p>
      <p>
        <br />
        <br />
      </p>
    </div>
  );
}
