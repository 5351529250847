import React from "react";

export default function PrivacyPolicy() {
  return (
    <div>
      <br></br>
      {/* <h1>Privacy Policy</h1> */}
      {/* <br></br> */}
      <h6>Venndii Inc. (DBA and Trade name as &ldquo;Wander&rdquo;)</h6>
      <h6>
        You are entering into this Contract with Venndii Inc. (also referred to
        as &ldquo;Wander&rdquo;,&nbsp; &ldquo;we&rdquo; and
        &ldquo;us&rdquo;).Wander Social
      </h6>
      <br></br>

      <h3>Your Privacy Matters</h3>
      <p>
        Welcome to Wander.&nbsp; We are a social network and an online platform
        dedicated to the travel and adventure seeking community. People use our
        Services to find personal connections with others and find information.
        Our Privacy Policy applies to any Member or Visitor to our Service.
      </p>
      <p>
        This Privacy Policy applies when you use our Services (described below).
        We offer our users choices about the data we collect, use and share as
        described in this Privacy Policy.
      </p>
      <p>This Privacy Policy applies to your use of our Services.</p>
      <p>
        This Privacy Policy applies to Wander.social and other Wander-related
        sites, apps, communications and services (&ldquo;
        <strong>Services).</strong>
      </p>
      <h3>Data Controllers and Contracting Parties</h3>
      <p>
        As a Visitor or Member of our Services, the collection, use and sharing
        of your personal data is subject to this Privacy Policy and other
        documents referenced in this Privacy Policy, as well as updates.
      </p>
      <h3>Change</h3>
      <p>
        Changes to the Privacy Policy apply to your use of our Services after
        the &ldquo;effective date.&rdquo;
      </p>
      <p>
        Wander (&ldquo;we&rdquo; or &ldquo;us&rdquo;) can modify this Privacy
        Policy, and if we make material changes to it, we will provide notice
        through our Services, or by other means, to provide you the opportunity
        to review the changes before they become effective. If you object to any
        changes, you may close your account by deleting the app.
      </p>
      <p>
        You acknowledge that your continued use of our Services after we publish
        or send a notice about our changes to this Privacy Policy means that the
        collection, use and sharing of your personal data is subject to the
        updated Privacy Policy, as of its effective date.
      </p>
      <h3>1.1 Data You Provide To Us</h3>
      <p>You provide data to create an account with us.</p>
      <h4>Registration</h4>
      <p>
        To create an account you need to provide data including your name, email
        address and/or mobile number, and a password. If you register for a
        premium Service, you will need to provide payment (e.g., credit card)
        and billing information. You create your Wander profile (a complete
        profile helps you get the most from our Services).
      </p>
      <h4>Profile</h4>
      <p>
        You have choices about the information on your profile, such as your
        education, occupation, interests, photos, city of area and personal
        endorsements.
      </p>
      <p>
        You do not have to provide additional information on your profile;
        however, profile information helps you to get more from our Services,
        including helping us find compatible social connections for you. It is
        your choice whether to include sensitive information on your profile and
        to make that sensitive information public. Please do not post or add
        personal data to your profile that you would not want to be publicly
        available.
      </p>
      <p>
        You give other data to us, such as by syncing your contacts or calendar.
      </p>
      <h4>Posting and Uploading</h4>
      <p>
        We collect personal data from you when you provide, post or upload it to
        our Services.
      </p>
      <p>
        You don&rsquo;t have to post or upload personal data; though if you
        don&rsquo;t, it may limit your ability to engage and connect with
        members over our Services.
      </p>
      <h4>Partners</h4>
      <p>
        We receive personal data (e.g. your age, date of birth, email, location
        and phone number) about you when you use the Wander services, affiliates
        and our partners.
      </p>
      <h4>Related Companies and Other Services</h4>
      <p>
        We receive data about you when you use some of the other services
        provided by us or our affiliates. For example, you may choose to send us
        information about your personal contacts in third party apps and
        services, such as Facebook, for improved user networking on our
        Services.
      </p>
      <h3>1.3 Service Use</h3>
      <p>We log your visits and use of our Services, including mobile apps.</p>
      <p>
        We log usage data when you visit or otherwise use our Services,
        including our sites, app and platform technology, such as when you view
        or click on content or ads (on or off our sites and apps), perform a
        search, install or update one of our mobile apps. We use log-ins,
        cookies, device information and internet protocol (&ldquo;IP&rdquo;)
        addresses to identify you and log your use.
      </p>
      <h3>1.4 Cookies and Similar Technologies</h3>
      <p>We collect data through cookies and similar technologies.</p>
      <p>
        We use cookies and similar technologies (e.g., pixels and ad tags) to
        collect data (e.g., device IDs) to recognize you and your device(s) on,
        off and across different services and devices where you have engaged
        with our Services. We also allow some others to use cookies as described
        in our Cookie Policy. If you are outside the Designated Countries, we
        also collect (or rely on others who collect) information about your
        device where you have not engaged with our Services (e.g., ad ID, IP
        address, operating system and browser information) so we can provide our
        Members with relevant ads and better understand their
        effectiveness.&nbsp;
      </p>
      <h3>1.5 Your Device and Location</h3>
      <p>
        We receive data from your devices and networks, including location data.
      </p>
      <p>
        When you visit or leave our Services (including some plugins and our
        cookies or similar technology on the sites of others), we receive the
        URL of both the site you came from and the one you go to and the time of
        your visit. We also get information about your network and device (e.g.,
        IP address, proxy server, operating system, web browser and add-ons,
        device identifier and features, cookie IDs and/or ISP, or your mobile
        carrier). If you use our Services from a mobile device, that device will
        send us data about your location based on your phone settings. We will
        ask you to opt-in before we use GPS or other tools to identify your
        precise location. Wander collects location data to enable matching algorithm & safety tracking even when the app is closed or not in use.
      </p>
      <h3>1.6 Messages</h3>
      <p>
        If you communicate through our Services we collect information about
        you, when you send, receive, or engage with messages in connection with
        our Services. For example, if you get a Wander connection request, we
        track whether you have acted on it and will send you reminders. We also
        use automatic scanning technology on messages to support and protect our
        site. For example, we use this technology to suggest possible responses
        to messages and to manage or block content that violates our User
        Agreement from our Services.
      </p>
      <h3>1.7 Sites and Services of Others</h3>
      <p>
        We get data when you visit sites that include our ads, cookies or some
        of our plugins or when you log-in to others&rsquo; services with your
        Wander account. We receive information about your visits and interaction
        with services provided by others when you log-in with Wander or visit
        others&rsquo; services that include some of our plugins or our ads,
        cookies or similar technologies
      </p>
      <h3>1.8 Other</h3>
      <p>
        We are constantly improving our Services, which means we get new data
        and create new ways to use data.
      </p>
      <p>
        Our Services are dynamic, and we often introduce new features, which may
        require the collection of new information. If we collect materially
        different personal data or materially change how we collect, use or
        share your data, we will notify you and may also modify this Privacy
        Policy.
      </p>
      <p>
        <strong>Use of Data</strong>
      </p>
      <p>
        We use your data to provide, support, personalize and develop our
        Services.
      </p>
      <p>
        How we use your personal data will depend on which Services you use, how
        you use those Services and the choices you make in your settings. We use
        the data that we have about you to provide and personalize our Services,
        including with the help of automated systems and inferences we make, so
        that our Services (including ads) can be more relevant and useful to you
        and other members.
      </p>
      <h3>2.1 Services</h3>
      <p>
        Our Services help you connect with others, find and be found for
        leisure, work and travel. We use your data to authorize access to our
        Services and honor your settings.
      </p>
      <p>
        Our Services allow you to connect and stay in touch and up to date with
        other members and other professional and personal contacts.
      </p>
      <p>
        We use data about you (such as your profile, profiles you have viewed or
        data provided through contact uploads or partner integrations) to help
        others find your profile, suggest connections for you and others and
        enable you to invite others to become a Member and connect with you. You
        can also opt-in to allow us to use your precise location or proximity to
        others for certain tasks (e.g. to suggest other nearby Members for you
        to connect with).
      </p>
      <p>
        It is your choice whether to invite someone to our Services, send a
        connection request, or allow another Member to become your connection.
        When you invite someone to join or connect with you, your invitation
        will show your basic profile information (e.g., name, profile photo,
        age)
      </p>
      <h3>2.2 Premium Services</h3>
      <p>
        Our premium Services help paying users to search and contact Members
        through our Services.
      </p>
      <p>
        We sell premium Services that provide our customers and subscribers with
        customized-search functionality and tools (including messaging and
        activity alerts).
      </p>
      <p>
        We do not provide contact information to other members as part of these
        premium Services without your consent.
      </p>
      <p>
        Members with Premium Services can store information they have about you
        in our premium Services. The data stored about you by these members are
        subject to the policies of the End User Agreement and Privacy Policy
        Agreement.
      </p>
      <h3>2.3 Communications</h3>
      <p>
        We contact you and enable communications between Members. We offer
        settings to control what messages you receive and how often you receive
        some types of messages.
      </p>
      <p>
        We will contact you through email, mobile phone, notices posted on our
        websites or apps, messages to your Wander inbox, and other ways through
        our Services, including text messages and push notifications.&nbsp; We
        will send you messages about the availability of our Services, security,
        or other service-related issues. We also send messages about how to use
        our Services, network updates, reminders and promotional messages from
        us and our partners. You may change your communication preferences at
        any time. Please be aware that you cannot opt out of receiving service
        messages from us, including security and legal notices.
      </p>
      <p>
        We also enable communications between you and others through our
        Services, including for example invitations and messages between
        connections.
      </p>
      <h3>2.4 Advertising</h3>
      <p>
        We serve you tailored ads both on and off our Services. We offer you
        choices regarding personalized ads, but you cannot opt-out of seeing
        other ads.
      </p>
      <p>
        We target (and measure the performance of) ads to Members, Visitors and
        others both on and off our Services directly or through a variety of
        partners, using the following data, whether separately or combined:
      </p>
      <ul>
        <li>
          Data from advertising technologies on and off our Services, pixels, ad
          tags, cookies, and device identifiers;
        </li>
        <li>
          Member-provided information (e.g., profile, contact information,
          location)
        </li>
        <li>
          Data from your use of our Services (e.g., search history, feed,
          content you read, who you follow or is following you, connections,
          group participation, page visits, videos you watch, clicking on an ad,
          etc.), including as described in Section 1.3;
        </li>
        <li>
          Information from advertising partners, vendors and{" "}
          <a
            href="https://www.linkedin.com/help/linkedin/answer/85809?trk=microsites-frontend_legal_privacy-policy&amp;lang=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.linkedin.com/help/linkedin/answer/85809?trk=microsites-frontend_legal_privacy-policy&amp;lang=en
          </a>
          publishers; and
        </li>
        <li>
          Information inferred from data described above (e.g using your feed
          activity to infer your interests; or using device data to recognize
          you as a Member).
        </li>
      </ul>
      <p>
        We will show you ads called sponsored content which look similar to
        non-sponsored content, except that they are labeled as advertising
        (e.g., as &ldquo;ad&rdquo; or &ldquo;sponsored&rdquo;). If you take a
        social action (such as like, comment or share) on these ads, your action
        is associated with your name and viewable by others, including the
        advertiser.
      </p>
      <p>
        Subject to your settings if you take a social action on the Wander
        Services, that action may be mentioned with related ads.
      </p>
      <h4>Ad Choices</h4>
      <p>
        We adhere to self-regulatory principles for interest-based advertising
        and participate in industry opt-outs from such ads. This does not opt
        you out of receiving advertising; you will continue to get other ads by
        advertisers not listed with these self regulatory tools. You can also
        opt-outs specifically from our uses of certain categories of data to
        show you more relevant ads.
      </p>
      <h4>Info to Ad Providers</h4>
      <p>
        We do not share your personal data with any third-party advertisers or
        ad networks except for: (i) hashed IDs or device identifiers (to the
        extent they are personal data in some countries); (ii) with your
        separate permission (e.g., in a lead generation form) or (iii) data
        already visible to any users of the Services (e.g., profile). However,
        if you view or click on an ad on or off our Services, the ad provider
        will get a signal that someone visited the page that displayed the ad,
        and they may, through the use of mechanisms such as cookies, determine
        it is you. Advertising partners can associate personal data collected by
        the advertiser directly from you with hashed IDs or device identifiers
        received from us. In such instances, we seek to contractually require
        such advertising partners to obtain your explicit, opt-in consent before
        doing so.
      </p>
      <h3>2.5 Marketing</h3>
      <p>We promote our Services to you and others.</p>
      <p>
        In addition to advertising our Services, we use Members&rsquo; data and
        content for invitations and communications promoting membership and
        network growth, engagement and our Services, such as by showing your
        connections that you have used a feature on our Services.
      </p>
      <h3>2.6 Developing Services and Research</h3>
      <p>We develop our Services and conduct research.</p>
      <h4>Service Development</h4>
      <p>
        We use data, including public feedback, to conduct research and
        development for our Services in order to provide you and others with a
        better, more intuitive and personalized experience, drive membership
        growth and engagement on our Services, and help connect&nbsp; members to
        each other and to grow economic opportunity.
      </p>
      <h4>Surveys</h4>
      <p>
        Polls and surveys are conducted by us and others through our Services.
        You are not obligated to respond to polls or surveys, and you have
        choices about the information you provide. You may opt-out of survey
        invitations.
      </p>
      <h3>2.7 Customer Support</h3>
      <p>We use data to help you and fix problems.</p>
      <p>
        We use data (which can include your communications) to investigate,
        respond to and resolve complaints and for Service issues (e.g., bugs).
      </p>
      <h3>2.8 Insights That Do Not Identify You</h3>
      <p>We use data to generate insights that do not identify you.</p>
      <p>
        We use your data to produce and share insights that do not identify you.
        For example, we may use your data to generate statistics about our
        members, their profession or industry, to calculate ad impressions
        served or clicked on, or to publish visitor demographics for a Service.
      </p>
      <h3>2.9 Security and Investigations</h3>
      <p>We use data for security, fraud prevention and investigations.</p>
      <p>
        We use your data (including your communications) for security purposes
        or to prevent or investigate possible fraud or other violations of our
        End User Agreement and/or attempts to harm our Members, Visitors or
        others.
      </p>
      <p>
        <strong>Data Sharing</strong>
      </p>
      <p>
        <strong>3.1 Our Services</strong>
      </p>
      <p>
        Any data that you include on your profile and any content you post or
        social action (e.g., likes, follows, comments, shares) you take on our
        Services will be seen by others, consistent with your settings.
      </p>
      <h4>Profile</h4>
      <p>
        Your profile is fully visible to all Members and customers of our
        Services. Subject to your settings, it can also be visible to others on
        or off of our Services (e.g., Visitors to our Services or users of
        third- party search engines). &nbsp; Your settings, degree of connection
        with the viewing Member, the subscriptions they may have, their usage of
        our Services, access channels and search types (e.g., by name or by
        keyword) impact the availability of your profile and whether they can
        view certain fields in your profile.
      </p>
      <h4>Posts, Likes, Follows, Comments, Messages</h4>
      <p>
        Our Services, currently or in the future, may allow viewing and sharing
        information including through posts, likes, follows and comments.
      </p>
      <ul>
        <li>
          When you share information or a post (e.g., an update, image, video or
          article) publicly it can be viewed by everyone and re-shared anywhere
          (subject to your settings). Members, Visitors and others will be able
          to find and see your publicly-shared content, including your name (and
          photo if you have provided one).
        </li>
        <li>
          In a group, posts are visible to others in the group. Your membership
          in groups is public and part of your profile, but you can change
          visibility in your settings.
        </li>
        <li>
          Any information you share through companies&rsquo; or other
          organizations&rsquo; pages on our Services will be viewable by it and
          others who visit those pages.
        </li>
        <li>
          When you connect to a person or a group, you are visible to others and
          that &ldquo;page owner&rdquo; as a participant.
        </li>
        <li>
          We let senders know when you act on their message, subject to your
          settings where applicable.
        </li>
        <li>
          Subject to your settings, we let a Member know when you view their
          profile.
        </li>
        <li>
          When you comment on another&rsquo;s content (including ads), others
          will be able to view these &ldquo;social actions&rdquo; and associate
          it with you (e.g., your name, profile and photo if you provided it).
        </li>
      </ul>
      <h3>3.2 Communication Archival</h3>
      <p>
        We enable archiving of messages by you and to those Members outside of
        our Services.
      </p>
      <h3>3.3 Others&rsquo; Services</h3>
      <p>
        You may link your account with others&rsquo; services so that they can
        look up your contacts&rsquo; profiles, post your shares on such
        platforms, or enable you to start conversations with your connections on
        such platforms. Excerpts from your profile will also appear on the
        services of others.
      </p>
      <p>
        Subject to your settings, other services may look up your profile. When
        you opt to link your account with other services, personal data will
        become available to them. The sharing and use of that personal data will
        be described in, or linked to, a consent screen when you opt to link the
        accounts. For example, you may link your Spotify, Linkedin,
        Facebook&nbsp; or Twitter account to share content from our Services
        into these other services, or your email provider may give you the
        option to upload your Wander contacts into its own service. Third-party
        services have their own privacy policies, and you may be giving them
        permission to use your data in ways we would not. You may revoke the
        link with such accounts.
      </p>
      <p>
        Subject to your settings, excerpts from your profile will appear on the
        services of others (e.g., search engine results, mail and messaging
        applications that show a user limited profile data of the person they
        are meeting or messaging, social media aggregators). &ldquo;Old&rdquo;
        profile information remains on these services until they update their
        data cache with changes you made to your profile.
      </p>
      <h3>3.4 Related Services</h3>
      <p>
        We share your data across our different Services and Wander affiliated
        entities.
      </p>
      <p>
        We will share your personal data with our affiliates to provide and
        develop our Services. We may combine information internally across the
        different Services covered by this Privacy Policy to help our Services
        be more relevant and useful to you and others.
      </p>
      <h3>3.5 Service Providers</h3>
      <p>We may use others or third parties to help us with our Services.</p>
      <p>
        We use others to help us provide our Services (e.g., maintenance,
        analysis, audit, payments, fraud detection, marketing and development).
        They will have access to your information as reasonably necessary to
        perform these tasks on our behalf and are obligated not to disclose or
        use it for other purposes.
      </p>
      <h3>3.6 Legal Disclosures</h3>
      <p>
        We may need to share your data when we believe it is required by law or
        to help protect the rights and safety of you, us or others.
      </p>
      <p>
        It is possible that we will need to disclose information about you when
        required by law, subpoena, or other legal process or if we have a good
        faith belief that disclosure is reasonably necessary to (1) investigate,
        prevent or take action regarding suspected or actual illegal activities
        or to assist government enforcement agencies; (2) enforce our agreements
        with you; (3) investigate and defend ourselves against any third-party
        claims or allegations; (4) protect the security or integrity of our
        Services (such as by sharing with companies facing similar threats); or
        (5) exercise or protect the rights and safety of Wander, our Members,
        personnel or others.
      </p>
      <p>
        We attempt to notify Members about legal demands for their personal data
        when appropriate in our judgment, unless prohibited by law or court
        order or when the request is an emergency. We may dispute such demands
        when we believe, in our discretion, that the requests are overbroad,
        vague or lack proper authority, but we do not promise to challenge every
        demand.
      </p>
      <h3>3.7 Change in Control or Sale</h3>
      <p>
        We may share your data when our business is sold to others, but it must
        continue to be used in accordance with this Privacy Policy.
      </p>
      <p>
        We can also share your personal data as part of a sale, merger or change
        in control, or in preparation for any of these events. Any other entity
        which buys us or part of our business will have the right to continue to
        use your data, but only in the manner set out in this Privacy Policy
        unless you agree otherwise.
      </p>
      <p>
        <strong>Data Retention &amp; Obligations</strong>
      </p>
      <h3>4.1 Data Retention</h3>
      <p>
        We keep most of your personal data for as long as your account is open.
      </p>
      <p>
        We generally retain your personal data as long as you keep your account
        open or as needed to provide you Services. This includes data you or
        others provided to us and data generated or inferred from your use of
        our Services.
      </p>
      <p>
        We will retain your information and keep your profile open, unless you
        close your account. In some cases we choose to retain certain
        information (e.g., insights about Services use) in a depersonalized or
        aggregated form.
      </p>
      <h3>4.2 Rights to Access and Control Your Personal Data</h3>
      <p>
        You can access or delete your personal data. You have many choices about
        how your data is collected, used and shared.
      </p>
      <p>
        We provide many choices about the collection, use and sharing of your
        data, from deleting or correcting data you include in your profile and
        controlling the visibility of your post to advertising opt-outs and
        communication controls. We offer you settings to control and manage the
        personal data we have about you.
      </p>
      <p>For personal data that we have about you, you can:</p>
      <ul>
        <li>
          <strong>Delete Data:</strong> You can ask us to erase or delete all or
          some of your personal data (e.g., if it is no longer necessary to
          provide Services to you).
        </li>
        <li>
          Change<strong> or Correct Data</strong>: You can edit some of your
          personal data through your account. You can also ask us to change,
          update or fix your data in certain cases, particularly if it is
          inaccurate.
        </li>
        <li>
          Object to, or Limit or Restrict<strong>, Use of Data:</strong> You can
          ask us to stop using all or some of your personal data (e.g., if we
          have no legal right to keep using it) or to limit our use of it (e.g.,
          if your personal data is inaccurate or unlawfully held).
        </li>
        <li>
          Right to Access and<strong>/or Take Your Data:</strong> You can ask us
          for a copy of your personal data and can ask for a copy of personal
          data you provided in machine readable form.
        </li>
      </ul>
      <h3>4.3 Account Closure</h3>
      <p>We keep some of your data even after you close your account.</p>
      <p>
        If you choose to close your Wander account, your personal data will
        generally stop being visible to others on our Services within 24 hours.
        We generally delete closed account information within 30 days of account
        closure, except as noted below.
      </p>
      <p>
        We retain your personal data even after you have closed your account if
        reasonably necessary to comply with our legal obligations (including law
        enforcement requests), meet regulatory requirements, resolve disputes,
        maintain security, prevent fraud and abuse (e.g., if we have restricted
        your account for breach of our Policy), enforce our User Agreement, or
        fulfill your request to "unsubscribe" from further messages from us. We
        will retain de-personalized information after your account has been
        closed.
      </p>
      <p>
        Information you have shared with others (e.g., through chats, updates or
        group posts) will remain visible after you close your account or delete
        the information from your own profile or messages, and we do not control
        data that other Members have copied out of our Services. Groups content
        and ratings or review content associated with closed accounts will show
        an unknown user as the source. Your profile may continue to be displayed
        in the services of others (e.g., search engine results) until they
        refresh their cache.
      </p>
      <p>Security and Miscellaneous</p>
      <h3>5.1. Security</h3>
      <p>
        We monitor for and try to prevent security breaches. Please use the
        security features available through our Services.
      </p>
      <p>
        We implement security safeguards designed to protect your data, such as
        HTTPS. We regularly monitor our systems for possible vulnerabilities and
        attacks. However, we cannot warrant the security of any information that
        you send us. There is no guarantee that data may not be accessed,
        disclosed, altered, or destroyed by breach of any of our physical,
        technical, or managerial safeguards.
      </p>
      <h3>5.2. Cross-Border Data Transfers</h3>
      <p>We may store and use your data outside your country.</p>
      <p>
        We process data both inside and outside of the United States and rely on
        legally-provided mechanisms to lawfully transfer data across
        borders.&nbsp; Countries where we process data may have laws which are
        different from, and potentially not as protective as, the laws of your
        own country.
      </p>
      <h3>5.3 Lawful Bases for Processing</h3>
      <p>
        We have lawful bases to collect, use and share data about you. You have
        choices about our use of your data.
      </p>
      <p>
        At any time, you can withdraw consent you have provided by going to
        settings.
      </p>
      <p>
        We will only collect and process personal data about you where we have
        lawful bases. Lawful bases include consent (where you have given
        consent), contract (where processing is necessary for the performance of
        a contract with you (e.g., to deliver the Wander Services you have
        requested) and &ldquo;legitimate interests.&rdquo;&nbsp;
      </p>
      <p>
        Where we rely on your consent to process personal data, you have the
        right to withdraw or decline your consent at any time and where we rely
        on legitimate interests, you have the right to object.&nbsp; If you have
        any questions about the lawful bases upon which we collect and use your
        personal data, please contact us.
      </p>
      <h3>5.5. Contact Information</h3>
      <p>You can contact us or use other options to resolve any complaints.</p>
      <p>
        If you have questions or complaints regarding this Policy, please first
        contact Wander online.
      </p>
      <p>Our Contact information:</p>
      <p>Email: travel@wander.social</p>
    </div>
  );
}
