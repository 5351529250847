import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import moment from "moment";
import Axios from "axios";
import GoogleMapReact from "google-map-react";
import { useParams } from "react-router";
// import env from "react-dotenv";
import { ClipLoader } from "react-spinners";

import { SOCKET_EVENTS } from "../constants";
import wanderLogo from "../assets/wander_logo.png";
import { SERVER_URL } from "../constants";
import errorLogo from "../assets/failure_logo.png";

const AnyReactComponent = () => (
  <img src={wanderLogo} width="50" height="50" alt="Logo" />
);

function LiveLocation() {
  const params = useParams();
  let [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [mapData, setMapData] = useState({
    center: { lat: 43.65107, lng: -79.347015 },
    zoom: 16,
  });
  let [error, setError] = useState("");

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "blue",
  };

  const formatExpiry = (date) => {
    const temp = moment(date.slice(0, 19) + "+" + date.slice(20, date.length));

    return temp.diff(moment());
  };

  const validateLiveLocation = async () => {
    setIsLoading(true);

    try {
      const { data: initialLocationData } = await Axios.post(
        `${SERVER_URL}/live-location/validate-live-location`,
        {
          liveLocationId: params["*"],
        }
      );

      setIsValid(true);

      connectSocket();

      if (
        !(
          window.sessionStorage.getItem("liveLocationLat") &&
          window.sessionStorage.getItem("liveLocationLng")
        )
      ) {
        setMapData({
          ...mapData,
          center: {
            lat: parseFloat(initialLocationData.lat),
            lng: parseFloat(initialLocationData.lng),
          },
        });
      }

      const timeOutInMs = formatExpiry(initialLocationData.expiry);

      setTimeout(() => {
        setIsValid(false);
        setError("Session Expired!");

        sessionStorage.removeItem("liveLocationLat");
        sessionStorage.removeItem("liveLocationLng");
      }, timeOutInMs);
    } catch (err) {
      setIsValid(false);

      if (err.response.status >= 400 && err.response.status < 500) {
        if (err.response.data.message.includes("is not allowed to be empty")) {
          setError("Live Location ID Not Found In Path Parameters!");
        } else {
          setError(err.response.data.message);
        }
      } else {
        setError("Something Went Wrong!");
      }

      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };

  const connectSocket = () => {
    const socket = io(SERVER_URL, {
      autoConnect: false,
      query: {
        socketKind: SOCKET_EVENTS.LIVE_LOCATION,
        liveLocationId: params["*"] ? params["*"] : null,
      },
    });

    socket.connect();

    socket.on("connect", () => {
      console.log("connected!");
    });

    socket.on(SOCKET_EVENTS.LIVE_LOCATION, (liveLocationData) => {
      if (liveLocationData.status) {
        window.sessionStorage.setItem("liveLocationLat", liveLocationData.lat);
        window.sessionStorage.setItem("liveLocationLng", liveLocationData.lng);

        setMapData({
          ...mapData,
          center: {
            lat: parseFloat(liveLocationData.lat),
            lng: parseFloat(liveLocationData.lng),
          },
        });
      } else {
        setIsValid(false);
        setError("Session Expired!");

        sessionStorage.removeItem("liveLocationLat");
        sessionStorage.removeItem("liveLocationLng");
      }
    });
  };

  useEffect(() => {
    if (
      window.sessionStorage.getItem("liveLocationLat") &&
      window.sessionStorage.getItem("liveLocationLng")
    ) {
      console.log(
        window.sessionStorage.getItem("liveLocationLat"),
        window.sessionStorage.getItem("liveLocationLng")
      );

      setMapData({
        ...mapData,
        center: {
          lat: parseFloat(window.sessionStorage.getItem("liveLocationLat")),
          lng: parseFloat(window.sessionStorage.getItem("liveLocationLng")),
        },
      });
    }
  }, []);

  useEffect(() => {
    if (params) {
      validateLiveLocation();
    }
  }, [params]);

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0,0,0,.1)",
    textAlign: "center",
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        textAlign: "center",
        verticalAlign: "center",
        alignItems: "center",
      }}
    >
      {isValid && !isLoading ? (
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCz34MFfsd9qjQmy80MSLbNpUeI2AR2Itk" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          center={mapData.center}
          zoom={mapData.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          <AnyReactComponent
            lat={mapData.center.lat}
            lng={mapData.center.lng}
            text="My Marker"
          />
        </GoogleMapReact>
      ) : null}

      {isLoading && !isValid ? (
        <div style={{ marginTop: "36vh" }}>
          <ClipLoader
            color={"#574599"}
            loading={isLoading}
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          ></ClipLoader>
        </div>
      ) : null}

      {!isLoading && !isValid ? (
        <div style={containerStyle}>
          <img src={errorLogo} alt="My Logo" className="logo" width="100" />
          <p>{error}</p>
        </div>
      ) : null}
    </div>
  );
}

export default LiveLocation;
