import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useSearchParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
// import env from "react-dotenv";
import { SERVER_URL } from "../constants";

import successLogo from "../assets/success_logo.png";
import warningLogo from "../assets/alert_logo.webp";
import errorLogo from "../assets/failure_logo.png";

const EmailVerified = () => {
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState({
    logo: "",
    message: "",
  });

  const verifyEmail = async () => {
    const email = searchParams.get("email");
    const code = searchParams.get("code");

    try {
      setIsLoading(true);

      const { data } = await Axios.get(
        `${SERVER_URL}/auth/verify-email?email=${email}&code=${code}`,
        {
          email,
          code,
        }
      );

      setContent({ logo: successLogo, message: data });
    } catch (err) {
      console.log(err);

      if (
        err.response.status === 400 &&
        err.response.data.includes("already been verified")
      ) {
        setContent({ logo: warningLogo, message: err.response.data });
      } else {
        setContent({ logo: errorLogo, message: err.response.data });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0,0,0,.1)",
    textAlign: "center",
  };

  return (
    <div style={containerStyle}>
      {isLoading ? null : (
        <img src={content.logo} alt="My Logo" className="logo" width="100" />
      )}
      <h1>Email Verification</h1>
      <p>
        {isLoading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          content.message
        )}
      </p>
    </div>
  );
};

export default EmailVerified;
