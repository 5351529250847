import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Axios from "axios";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import sha256 from "js-sha256";
// import env from "react-dotenv";
import { SERVER_URL } from "../constants";

import "react-toastify/dist/ReactToastify.css";

import wanderLogo from "../assets/wander_logo.png";

function PasswordRecovery() {
  const [recoveryData, setRecoveryData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [searchParams] = useSearchParams();

  const handleResetPassword = async () => {
    const email = searchParams.get("email");
    const code = searchParams.get("code");

    const { password, confirmPassword } = recoveryData;

    if (!password || !confirmPassword) {
      return toast.error("Please fill all the fields!");
    }

    if (password !== confirmPassword) {
      return toast.error("Passwords didn't match!");
    }

    try {
      setIsLoading(true);
      await Axios.post(`${SERVER_URL}/auth/reset-password`, {
        email,
        code,
        newPassword: sha256(`wander${password}app`),
      });
      setIsPasswordReset(true);
    } catch (err) {
      console.log(err);

      if (err.response.status >= 400 && err.response.status < 500) {
        toast.error(err.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <ToastContainer />
      <img
        src={wanderLogo}
        style={{ marginTop: "2%", marginBottom: "5%" }}
        width="150"
        height="150"
        alt="Logo"
      />
      {isPasswordReset ? (
        <div>
          <h3>Password Reset Successfully</h3>
          <h5>You can now login from the app.</h5>
        </div>
      ) : (
        <div>
          <h1>Password Recovery</h1>
          <div
            className="mt-3"
            style={{ paddingLeft: "35%", paddingRight: "35%" }}
          >
            <Form.Control
              className="mt-2"
              onChange={(e) => {
                setRecoveryData({ ...recoveryData, password: e.target.value });
              }}
              type="password"
              placeholder="Password"
            />
            <Form.Control
              className="mt-2"
              onChange={(e) => {
                setRecoveryData({
                  ...recoveryData,
                  confirmPassword: e.target.value,
                });
              }}
              type="password"
              placeholder="Confirm Password"
            />
          </div>
          <Button
            onClick={handleResetPassword}
            className="mt-4"
            variant="primary"
            disabled={isLoading}
          >
            Reset Password
            {isLoading ? (
              <span
                style={{ marginLeft: 5 }}
                className="spinner-border spinner-border-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
          </Button>{" "}
        </div>
      )}
    </div>
  );
}

export default PasswordRecovery;
