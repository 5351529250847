import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import PasswordRecovery from "./components/password-recovery";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";
import EmailVerification from "./components/email-verification";
import LiveLocation from "./components/live-location";

function App() {
  return (
    <div style={{ alignItems: "center" }} className="App container">
      <BrowserRouter>
        <Routes>
          <Route path="password-recovery" element={<PasswordRecovery />} />
          <Route path="policy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="policy_ENGLISH" element={<PrivacyPolicy />} />
          <Route path="terms_ENGLISH" element={<Terms />} />
          <Route path="policy_SPANISH" element={<PrivacyPolicy />} />
          <Route path="terms_SPANISH" element={<Terms />} />
          <Route path="policy_FRENCH" element={<PrivacyPolicy />} />
          <Route path="terms_FRENCH" element={<Terms />} />
          <Route path="email-verification" element={<EmailVerification />} />
          <Route path="live-location/*" element={<LiveLocation />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
